import axios from 'axios';

const responseInterceptor = (response) => {
	switch (response.status) {
		case 200:
			break;
		default:
			console.log(response);
	}

	return response;
};

const errorInterceptor = (error) => {
	if (!error.response) {
		return Promise.reject(error);
	}

	switch (error.response.status) {
		case 400:
			console.error(error.response.status, error.message);
			break;
		default:
			console.error(error.response.status, error.message);
	}
	return Promise.reject(error);
};

export default ({ requiresAuth }) => {
	const user = JSON.parse(localStorage.getItem('user'));
	var options = {
		// baseURL: 'http://0.0.0.0:8080'
		baseURL: 'https://infinity-backend.braiven.com'
	};

	if (requiresAuth) {
		options.headers = {
			Authorization: `Bearer ${user.token}`
		};
	}

	const instance = axios.create(options);
	instance.interceptors.response.use(responseInterceptor, errorInterceptor);

	return instance;
};
